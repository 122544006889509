import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTopControls } from '@/components/PageTopControls/PageTopControls';
import { IconBox } from '@/components/UI/IconBox/IconBox.styles';
import * as S from '@/views/Error/Error.styles';
import { useRouter } from 'next/router';
import { ROUTES } from '@/constants/routes';

interface ErrorViewProps {
  title?: string;
  subtitle?: string;
  statusCode?: number;
  buttonText?: string;
  onClick?: () => void;
}

export const ErrorView: React.FC<ErrorViewProps> = ({
  title,
  subtitle,
  statusCode,
  buttonText,
  onClick,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [headerHeight, setHeaderHeight] = useState(0);
  const isUnexpectedError = statusCode === 500;
  const isNotFound = statusCode === 404;
  const imageSrc = isUnexpectedError
    ? '/images/unexpected-error.png'
    : '/images/not-found.png';
  const finalTitle =
    title ??
    t(isNotFound ? 'pages.error.not_found' : 'pages.error.unexpected_error');

  const finalSubtitle =
    subtitle ??
    t(
      isNotFound
        ? 'pages.error.not_found_subtitle'
        : 'pages.error.unexpected_error_subtitle'
    );

  const finalButtonText =
    buttonText ??
    t(isNotFound ? 'pages.error.go_to_main' : 'pages.error.try_again');

  function onButtonClick() {
    if (onClick) return onClick();
    router.replace(ROUTES.home);
  }

  return (
    <>
      <PageTopControls isMainHeader onInitialRender={setHeaderHeight} />
      <S.Box $headerHeight={headerHeight}>
        <IconBox $src={imageSrc} width={255} height={218} />

        <S.Title $variant="3">{finalTitle}</S.Title>

        <S.Text $variant="1">{finalSubtitle}</S.Text>

        {(isNotFound || isUnexpectedError || (buttonText && onClick)) && (
          <S.Action appearance="secondary" isFullWidth onClick={onButtonClick}>
            {finalButtonText}
          </S.Action>
        )}
      </S.Box>
    </>
  );
};
