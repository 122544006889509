import styled from 'styled-components';
import { Typography } from '@/components/UI/Typography/Typography.styles';
import { Button } from '@/components/UI/Button/Button';

export const Box = styled.div<{ $headerHeight: number }>`
  height: calc(100dvh - ${({ $headerHeight }) => $headerHeight}px);
  background-color: ${({ theme }) => theme.palette.primary.background};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
`;

export const Title = styled(Typography.Header)`
  margin: 20px 0 15px;
  text-align: center;
`;

export const Text = styled(Typography.Body)`
  text-align: center;
  color: ${({ theme }) => theme.palette.secondary.labels};
`;

export const Action = styled(Button)`
  margin-top: 20px;
`;
